// Body
$body-bg: #f8fafc;

// Typography
$font-family-sans-serif: 'Work Sans', sans-serif;
$font-principal: 'Merriweather Sans', sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;
$fontsize: 14px;

// Colors
// $blue: #3490dc;
// $indigo: #6574cd;
// $purple: #9561e2;
// $pink: #f66d9b;
// $red: #e3342f;
// $orange: #f6993f;
// $yellow: #ffed4a;
// $green: #38c172;
// $teal: #4dc0b5;
// $cyan: #6cb2eb;


// Colors
$white:    #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black:    #000 !default;
$blue:    #0067b8 !default;
$indigo:  #6574cd !default;
$purple:  #3D0B61 !default;
$pink:    #FFCCBC !default;
$red:     #dc3545 !default;
$orange:  #EF7B10 !default;
$yellow:  #F39A02 !default;
$green:   #38c172 !default;
$teal:    #20c997 !default;
$cyan:    #6cb2eb !default;
$primary:       $orange !default;
$secondary:     $blue !default;
// $secondary:     $gray-600 !default;
$success:       $green !default;
$info:          $cyan !default;
$warning:       $yellow !default;
$danger:        $red !default;
$light:         $gray-200 !default;
$dark:          $gray-800 !default;

$naranja: #EF7B10;
$naranjita: #f39d4d;
$negrotxt: #201D1D;
.form-control:focus {
    border-color: #F77123 !important;
    // border-color: white !important;
    // box-shadow: none !important;
    box-shadow: 0 0  10px rgba(235,93,19,.2) !important;
  }
